<template>
  <div class="build views"
       :style="viewStyle">
    <container :id="id"
               :target="target"
               :option="option"
               ref="container"></container>
  </div>
</template>
<script>
import init from '@/mixins/'
export default {
  props: {
    target: String,
    option: Object,
    id: [String, Number]
  },
  mixins: [init],
  data () {
    return {
      viewStyle: {}
    }
  },
 async mounted () {
	let token = this.GetQueryString('token')
	if(!token){
		if(window.location.href.includes('1705222921787')){
			this.showMessage('您未登录',1)
		}else if(window.location.href.includes('1703486190800')){
			this.showMessage('您未登录',2)
		}else if(window.location.href.includes('1705207894842')){
			this.showMessage('您未登录',3)
		}
	}else{
		await this.isToken(token)
	}
	
	localStorage.setItem('addressName','') 	
	localStorage.setItem('name','')
  },
  create(){
  },
  methods:{
	async isToken(token){

	   await this.$axios({
		    method: "POST",
		    url: this.$domain+'mch/islogin',
			data:{token:token}
		}).then(res => {
			if(res.data.status==404){
				if(window.location.href.includes('1705222921787')){
					this.showMessage('您未登录',1)
				}else if(window.location.href.includes('1703486190800')){
					this.showMessage('您未登录',2)
				}else if(window.location.href.includes('1705207894842')){
					this.showMessage('您未登录',3)
				}
				localStorage.setItem('token',token)
			}else{
				localStorage.setItem('token',token)
			}
		});
	},
	showMessage(msg,type=1) {
		const message = window.document.createElement('div');
		message.style.position = 'fixed';
		message.style.top = '100px';
		message.style.right = '42%';
		message.style.width = "16%";
		message.style.fontSize = "14px";
		message.style.color = "red";
		message.style.background = "rgb(247 207 207)"
		message.style.borderRadius = '6px';
		message.style.padding = '12px';
		message.style.zIndex = 9999
		
		message.textContent = `${msg}`;
	 
		window.document.body.appendChild(message);
	 
		// 1秒后移除消息提示
		setTimeout(() => {
			if(type==1){
				// window.location.href = "https://stat.if1f.com/admin"
				window.location.href = "https://data.stat.if1f.com/admin"
			}else if(type==2){
				// window.location.href = "https://stat.if1f.com/member"
				window.location.href = "https://data.stat.if1f.com/total"
			}else{
				// window.location.href = "https://stat.if1f.com/mch"
				window.location.href = "https://data.stat.if1f.com/mch"
			}
			
		}, 200);
	},
	GetQueryString(name) {
	  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	  var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
	  var context = "";
	  if (r != null)
		  context = decodeURIComponent(r[2]);
	  reg = null;
	  r = null;
	  return context == null || context == "" || context == "undefined" ? "" : context;
	}
  }
}
</script>