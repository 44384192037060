<template>
	<div class="main">
		<div class="block">
			<el-date-picker
				v-model="value1"
				class="picker"
				popper-class="elDatePicker"
				type="date"
				align="center"
				format="yyyy/MM/dd"
				value-format="yyyy-MM-dd"
				:clearable="false"
				prefix-icon="''"
				@click="sumbit"
				placeholder="开启日期">
			</el-date-picker>
		</div>
	</div>
</template>
<script>
export default {
  name: 'dateChoose',
  data () {
    return {
		value1: '',
    }
  },
  created(){
	this.getNow()  
  },
  watch:{
	  value1(){
		  console.log(this.value1)
	  }
  },
  computed: {
  },
  methods: {
	getNow () {
		let date = new Date()
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()
		this.value1 = year + '-' + month + '-' + day
	},
	sumbit(){
		return 'ssss'
	}
  }
}
</script>
<style scoped>
.main{
	display: flex;
}
.picker{
  background-color: transparent;
  font-size: 16px;
}
::v-deep .el-input__inner {
	background-color:transparent;
	border-color: transparent;
}
.elDatePicker{
	background-color: transparent;
}
</style>