<template>
  <div :class="[b(),className]"
       :style="styleSizeName">
    <div :ref="id"
         :style="styleChartName"  @click="handleMapClick" @mousedown="mousedown">
	</div>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "map",
  data () {
    return {
      bannerCount: 0,
      bannerCheck: "",
      centerData: [],
      zoomData: 1,
	  isClick:0,
	  firstTime: '', //开始时间
	  lastTime: '', 
	  level:'',
	  locationData2:[],
	  filings:1
    };
  },
  watch: {
    mapData () {
      this.updateChart();
    },
    dataChartLen () {
      this.setBanner();
    },
    bannerTime () {
      this.setBanner();
    },
    banner: {
      handler () {
        this.setBanner();
      },
      immediate: true
    },
    width () {
      this.updateData();
    },
    height () {
      this.updateData();
    },
    zoom: {
      handler () {
        this.zoomData = this.zoom;
      },
      immediate: true
    },
  },
  computed: {
    zoomShow () {
      return this.option.zoomShow || 1;
    },
    zoom () {
      return this.option.zoom || 1;
    },
    mapData () {
      return this.option.mapData || {};
    },
    borderWidth () {
      return this.option.borderWidth || 3;
    },
    borderColor () {
      return this.option.borderColor || "#389BB7";
    },
    areaColor () {
      return this.option.areaColor || "#0c162f";
    },
    empColor () {
      return this.option.empColor || "#fff";
    },
    empAreaColor () {
      return this.option.empAreaColor || "yellow";
    },
    color () {
      return this.option.color || "#fff";
    },
    roam () {
      return this.vaildData(this.option.roam, true)
    },
    fontSize () {
      return this.option.fontSize || 24;
    },
    bannerTime () {
      return this.option.bannerTime || 3000;
    },
    banner () {
      return this.option.banner;
    },
    locationData () {
      return (this.dataChart || []).map(ele => {
        ele.zoom = ele.zoom || 1;
        const zoomData = this.zoomData < 1 ? 1 : this.zoomData;
		if(ele.value!='deng'){
        return Object.assign(
          (() => {
            if (ele.zoom <= zoomData) {
              return {
                name: ''//地图网点名称ele.name
              };
            }
            return {};
          })(),
          {
            value: [ele.lng, ele.lat, ele.value]
          }
        );
		}
      });
    },
  },
  methods: {
    resetBanner () {
      this.$nextTick(() => {
        // this.myChart.dispatchAction({
        //   type: "hideTip"
        // });
        this.myChart.dispatchAction({
          type: "downplay"
        });
      });
    },
    setBanner () {
      clearInterval(this.bannerCheck);
      if (this.banner) {
        this.bannerCheck = setInterval(() => {
          const curr = this.bannerCount % this.dataChartLen;
          this.myChart.dispatchAction({
            type: "showTip",
            seriesIndex: "0",
            dataIndex: curr
          });
          this.myChart.dispatchAction({
            type: "downplay"
          });
          this.myChart.dispatchAction({
            type: "highlight",
            dataIndex: curr
          });
          this.bannerCount += 1;
        }, this.bannerTime);
      }
    },
	 // 设置地图的呼吸灯效果
	breatheEffect(options) {
		const series = options.series[1];
		this.myChart.setOption(options);
		// 呼吸灯效果的动画函数
		const intervalId = setInterval(()=> {
			// 获取当前打点的颜色
			
			const fontSize = series.markPoint.symbolSize*0.5;
			series.markPoint.symbolSize = fontSize
			options.series[1]=series
			this.myChart.setOption(options);

		}, options.series[1].markPoint.animationDuration);
		
		// 停止呼吸灯效果
		setTimeout(()=> {
			options.series[1].data = []
			this.myChart.setOption(options);
			clearInterval(intervalId);
		}, 2500);
	},
    updateChart (p={}) {
		localStorage.setItem('isClick',3)
		let center = 2
		if(p&&p.center&&p.center==1){
			center = 1

			p.center = 2
		}
		if(!p||!p.name){
			let ppam = {name:localStorage.getItem('name')}
			p = Object.assign(ppam, p)
		}
		let config = {
			url:this.mapData,
			headers:{proxy:true},
			method:'post',
			data:p
		}
	this.$axios(config).then(res => {
        const   data= res.data;
		if(data.length==0) return
		this.level = data.level?data.level:''
		if(center==1){
			let address = localStorage.getItem('addressName')
			if(!address||address != data.address){
				this.centerData = []
				localStorage.setItem('addressName',data.address)
			}
		}
        const optionData = this.deepClone(data);
        window.echarts.registerMap("HK", optionData);
        const option = {
          tooltip: (() => {
            return Object.assign(
              (() => {
                if (this.formatter) {
                  return {
                    formatter: name => {
                      return this.formatter(name, this.dataChart);
                    }
                  };
                }
                return {};
              })(),
              {
                backgroundColor: this.option.tipBackgroundColor,
                textStyle: {
                  fontSize: this.option.tipFontSize,
                  color: this.option.tipColor
                }
              }
            );
          })(),
          geo: Object.assign(
            (() => {
              if (!this.validatenull(this.centerData)) {
                return {
                  center: this.centerData
                };
              }
              return {};
            })(),
            {
              map: "HK",
              label: {
                emphasis: {
                  show: false
                }
              },
              zoom: this.zoomData,
              layoutCenter: ["50%", "50%"],
              layoutSize: 1200,
              roam: this.roam,
			  animationDurationUpdate:0,
			  // zlevel: 2,
              label: {
                show: true,
                fontSize: this.fontSize,
                color: this.color
              },
              left: this.option.gridX,
              top: this.option.gridY,
              right: this.option.gridX2,
              bottom: this.option.gridY2,
              emphasis: {
                label: {
                  color: this.empColor
                },
                itemStyle: {
                  areaColor: this.empAreaColor
                }
              },
              itemStyle: {
                borderWidth: this.borderWidth,
                borderColor: this.borderColor,
                areaColor: this.areaColor
              }
            }
          ),
          series: [
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              showEffectOn: "emphasis",
              rippleEffect: {
                brushType: "fill",
                scale: 4
              },
              symbolSize: this.fontSize-4,
              hoverAnimation: true,
              data: this.locationData,
              label: {
                show: true,
                position: ["130%", "0"],
                fontSize: this.fontSize,
                color: this.color,
                formatter: params => {
                  return params.name;
                }
              },
              itemStyle: {
                color: this.color
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.fontSize + 20,
                  color: this.option.empColor
                },
                itemStyle: {
                  color: this.option.empColor
                }
              }
            },
			{
			  type: "effectScatter",
			  coordinateSystem: "geo",
			  showEffectOn: "emphasis",
			  rippleEffect: {
			    brushType: "fill",
			    scale: 4
			  },
			  symbolSize: this.fontSize-4,
			  hoverAnimation: true,
			  data: this.locationData2,
			  label: {
			    show: true,
			    position: ["130%", "0"],
			    fontSize: this.fontSize,
			    color: this.color,
			    formatter: params => {
			      return params.name;
			    }
			  },
			  itemStyle: {
			    color: this.filings==1?"rgba(255, 0, 60, 1)":"rgba(255, 215, 0, 1)"
			  },
			  emphasis: {
			    label: {
			      show: true,
			      fontSize: this.fontSize + 20,
			      color: this.option.empColor
			    },
			    itemStyle: {
			      color: this.option.empColor
			    }
			  },
			  			   // 打点的样式
			  markPoint: {
				  symbol: 'circle',
				  symbolSize: this.fontSize-4,
				  label: {
					  show: false
				  },
				  itemStyle: {
					normal: {
						color: '#ff0000'
					}
				  },
				  // 设置呼吸灯效果
				  animation: true,
				  animationDuration: 1000,
				  animationEasing: 'quadraticOut',
				  animationDelay: function (i) {
					  return i * 200;
				  }
			  }
			}
          ]
        };
        this.myChart.off("mouseover");
        this.myChart.off("mouseout");
        this.myChart.off("georoam");

        this.myChart.on("mouseover", () => {
          clearInterval(this.bannerCheck);
          this.resetBanner();
        });
        this.myChart.on("mouseout", () => {
          this.bannerCount = 0;
          this.setBanner();
        });
        this.myChart.on("georoam", e => {
          const option = this.myChart.getOption();
          const geo = option.geo[0];
          this.centerData = geo.center;
          this.zoomData = geo.zoom;
          // if (this.zoomData < 1) this.zoomData = 1;
        });
		  this.myChart.on("click", function(params) {
			  localStorage.setItem('isClick',1)
		  });
        this.myChart.resize();

		const deng = this.dataChart[this.dataChart.length-1]
		let that = this
		if(deng&&deng.value=='deng'&&deng.data.length>0){
			that.filings = deng.data[0].filings?deng.data[0].filings:that.filings
			that.locationData2 = [{name:'',value:[deng.data[0].lng,deng.data[0].lat,deng.data[0].value]}]
			option.series[1].data = [{name:'',value:[deng.data[0].lng,deng.data[0].lat,deng.data[0].value]}]
			let len = 1
			that.breatheEffect(option)
			if(deng.data.length>1){
				const intervalId2 = setInterval(()=> {
					// 获取当前打点的颜色
					that.filings = deng.data[len].filings?deng.data[len].filings:that.filings
					that.locationData2 = [{name:'',value:[deng.data[len].lng,deng.data[len].lat,deng.data[len].value]}]
					option.series[1].data = [{name:'',value:[deng.data[len].lng,deng.data[len].lat,deng.data[len].value]}]
					len++
					if(len>=deng.data.length){
						clearInterval(intervalId2)
					}
					that.breatheEffect(option)
				}, 3000);
			}

		}else{
			that.myChart.setOption(option, true);
		}
      })
    },
    handleMapClick(event) {
		let isClick = localStorage.getItem('isClick')
      // 判断点击事件发生在地图区域内还是外部
       this.lastTime = new Date().getTime();
        //>200毫秒
        if((this.lastTime - this.firstTime) < 200){
          if(isClick==2){
          	let p = {
          		type:1,
          		name:localStorage.getItem('name'),
          		address:localStorage.getItem('addressName'),
				level:this.level,
				center:1
          	}
          	this.updateData(p)
          }else{
          	localStorage.setItem('isClick',2)
          }
        }
        this.firstTime = ''
		this.lastTime  = ''


    },
	mousedown(){
		this.firstTime = new Date().getTime();
		localStorage.setItem('isClick',2)
	  },
  }
});
</script>